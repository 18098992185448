import $ from 'jquery';
import moment from 'moment';
import { BasePage } from './base_page';

export class ReportsPostBuildPage extends BasePage {
  // @override
  setup () {
    $('body').on('click', '.js-postBuild-monthNav', (event) => {
      event.preventDefault();
      const $button = $(event.currentTarget);
      const months = $button.data('months');
      const $startDate = $('#js-postBuild-startDate');
      const $endDate = $('#js-postBuild-endDate');
      var newStart = moment($startDate.val());
      var newEnd = moment($endDate.val());
      if (months < 0) {
        newStart = newStart.subtract(1, 'months').startOf('month');
        newEnd = newEnd.subtract(1, 'months').endOf('month');
      } else {
        newStart = newStart.add(1, 'months').startOf('month');
        newEnd = newEnd.add(1, 'months').endOf('month');
      }
      $startDate.val(newStart.format('YYYY-MM-DD'));
      $endDate.val(newEnd.format('YYYY-MM-DD'));
    });
  }
}
