export class CompanyCam {
  static setup() {
    const scrollWheel = document.querySelector('.company-cam-scroll-wheel');
    if (!scrollWheel) return;

    const savedScrollPosition = localStorage.getItem('companyCamPhotoScroll') || 0;
    window.scrollTo(0, savedScrollPosition);

    const selectedPhoto = document.querySelector('.selected');
    
    if (selectedPhoto) {
      const photoOffsetTop = selectedPhoto.offsetTop;
      const photoOffsetHeight = selectedPhoto.offsetHeight;
      const scrollWheelHeight = scrollWheel.offsetHeight;
      const scrollPosition = photoOffsetTop + (photoOffsetHeight / 2) - (scrollWheelHeight / 2);
      scrollWheel.scrollTop = scrollPosition;
    }

    window.addEventListener('scroll', () => {
      localStorage.setItem('companyCamPhotoScroll', window.scrollY);
    });
  }
}
