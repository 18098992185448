export class CopyToInputButton {
  static setup() {
    document.querySelectorAll('.copy-to-input').forEach(function(button) {
      button.addEventListener('click', function(event) {
        const value = event.target.getAttribute('data-value');
        const inputTarget = event.target.getAttribute('data-input-target');

        if (document.querySelector(inputTarget)) {
          document.querySelector(inputTarget).value = value;
        }
      });
    });
  }
}
