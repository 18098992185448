export class BasePage {
  constructor (root) {
    this.root = root;
  }

  setup () {
    // add event listeners
  }

  run () {
    // trigger initial action (e.g. perform http requests)
  }
}
