import SignaturePad from 'signature_pad';

export class ProjectReport {
  static setup() {
    this.copyQuantities();
    this.refreshCocoonSelects();
    this.setupSignatures();
  }

  static refreshCocoonSelects() {
    $('#project-report-extra-materials').on('cocoon:after-insert', function() {
      $(this).find('.selectpicker').selectpicker('refresh');
    });
    $('#project-report-returned-materials').on('cocoon:after-insert', function() {
      $(this).find('.selectpicker').selectpicker('refresh');
    });
  }

  static copyQuantities() {
    $('.copy-quantity').click(function() {
      let qtyInput = $(this).closest('tr').find('.project_report_project_report_lines_quantity_delivered input');
      let qtyToCopy = $(this).data('quantity');
      qtyInput.val(qtyToCopy);
    });
  }

  static setupSignatures() {
    if($('.project-signature-editor').length)  {
      const signaturePad = this.initializeSignaturePad();
      const mode = $('.project-signature-editor').data('mode');

      if(mode === 'editing') {
        this.displaySignaturePad(signaturePad);
      } else if(mode  === 'viewing') {
        this.displaySignatureImage();
      }

      $('.saveSignature').click(function() {
        const currentMode = $('.project-signature-editor').data('mode');
        if(currentMode === 'editing') {
          $('#project_report_signature_data').val(signaturePad.toDataURL());
          ProjectReport.displaySignatureImage();
          $('#project-signature-image').attr('src', signaturePad.toDataURL());
          $('.project-signature-editor').data('mode', 'viewing');
          $(this).text('Edit Signature');

        } else if(currentMode === 'viewing') {
          ProjectReport.displaySignaturePad();
          $('.project-signature-editor').data('mode', 'editing');
          $(this).text('Save Signature');
        }
      });
    }
  }

  static initializeSignaturePad() {
    const wrapper = document.getElementById('project-signature-pad');
    const canvas = wrapper.querySelector('canvas');
    return new SignaturePad(canvas);
  }

  static displaySignaturePad() {
    $('#project-signature-pad').show();
    $('#project-signature-image').removeClass('d-block').addClass('d-none');
  }

  static displaySignatureImage() {
    $('#project-signature-pad').hide();
    $('#project-signature-image').removeClass('d-none').addClass('d-block');
  }
}
