// https://developer.authorize.net/api/reference/features/accept-hosted.html#Integrating_an_Embedded_Iframe
export class AuthorizeNet {
  static setup() {
    if (document.querySelector('#send-token')) {
      document.querySelector('#send-token').submit();
    }
    
    if (!window.AuthorizeNetIFrame) window.AuthorizeNetIFrame = {};
    window.AuthorizeNetIFrame.onReceiveCommunication = this.onReceiveCommunication.bind(this);
  }

  static onReceiveCommunication(query) {
    const params = this.parseQueryString(query);

    // See task 3346 for more information
    const transIdMatch = query.match(/"transId":"(.*?)"/);
    const totalAmountMatch = query.match(/"totalAmount":"(.*?)"/);
    const responseCodeMatch = query.match(/"responseCode":"(.*?)"/);
    const transId = transIdMatch ? transIdMatch[1] : null;
    const totalAmount = totalAmountMatch ? totalAmountMatch[1] : null;
    const responseCode = responseCodeMatch ? responseCodeMatch[1] : null;
    switch (params['action']) {
    case 'successfulSave':
      break;
    case 'cancel':
      window.location.href = `${document.getElementById('iframe-holder').getAttribute('data-redirect-url')}?cancelled=true`;
      break;
    case 'resizeWindow':
      this.resizeIframe(params['width'], params['height']);
      break;
    case 'transactResponse':
      window.location.href = `${document.getElementById('iframe-holder').getAttribute('data-redirect-url')}?transId=${transId}&totalAmount=${totalAmount}&responseCode=${responseCode}`;
      break;
    }
  }

  static resizeIframe(width, height) {
    const iframe = document.getElementById('add-payment');
    iframe.style.width = width + 'px';
    iframe.style.height = height + 'px';
  }

  static parseQueryString(str) {
    const vars = [];
    const arr = str.split('&');
    let pair;
    for (let i = 0; i < arr.length; i++) {
      pair = arr[i].split('=');
      vars.push(pair[0]);
      vars[pair[0]] = decodeURIComponent(pair[1]);
    }
    return vars;
  }
}
