export class Warranties {
  static setup() {
    if( $('#warranty_extended_warranty_pricing').length) {
      this.showOrHidePerSquares();
      $('#warranty_extended_warranty_pricing').change(function() {
        Warranties.showOrHidePerSquares();
      });
    }
  }
  
  static showOrHidePerSquares() {
    let pricing = $('#warranty_extended_warranty_pricing').val();
    if(pricing === 'Per Square') {
      $('.warranty_extended_per_squares').parent('div').show();
    } else {
      $('#warranty_extended_per_squares').val('');
      $('.warranty_extended_per_squares').parent('div').hide();
    }
  }
}
