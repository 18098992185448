import $ from 'jquery'
import SignaturePad from 'signature_pad'

export class SignaturesModal {
  /**
   * Gets and displays the Signatures Modal window
   */
  static start (img) {
    const target = img.data('target')
    const url = img.data('url')
    $.ajax({
      type: 'GET',
      dataType: 'html',
      url: url,
      success: (data, status) => {
        const $data = $(data)
        $('body').prepend($data)
        this.setup(target, img)
        $('#js-signatures__new').modal({ backdrop: 'static' })
      }
    })
  }

  /**
   * Sets up the Signatures Modal window
   */
  static setup (target, img) {
    const wrapper = document.getElementById('signature-pad')
    const canvas = wrapper.querySelector('canvas')
    const signaturePad = new SignaturePad(canvas)
    const url = $('#signature-pad').data('url')

    $('body').on('click', '.saveSignature', function (event) {
      $('#signature').attr('src', signaturePad.toDataURL())
      $.ajax({
        type: 'POST',
        dataType: 'json',
        url: url,
        data: {
          'signature[image_data]': signaturePad.toDataURL()
        },
        success: (data, status) => {
          $(target).val(data.id)
          img.attr('src', data.image_data)
          $('#js-signatures__new').modal('hide').modal('dispose')
        }
      })
    })
  }
}
