export class BuildNotesEdit {
  static setup() {
    $('body').on('change', '.build-notes-edit', (event) => {
      const $input = $(event.currentTarget);
      const projectId = $input.data('project-id');
      const url = `/projects/${projectId}/build_notes`;

      $.ajax({
        type: 'POST',
        dataType: 'json',
        url: url,
        data: {
          'build_note[content]': $input.val()
        }
      });
    });
  }
}
