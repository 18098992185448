import $ from 'jquery';
import { BasePage } from './base_page';
import { Toast } from 'rad_common_js/src/toast';

export class AddonsIndexPage extends BasePage {
  // @override
  setup () {
    if (window.location.hash) {
      const tab = $(`a[href="${window.location.hash}-invoice"]`);
      if (tab.length) {
        tab.tab('show');
      }
    }

    $('#project_invoice_post_due_letters_sent').on('change', (event) => {
      const projectId = $('#past_due_project_form').data('projectId');
      $.ajax({
        type: 'PATCH',
        dataType: 'json',
        url: `/projects/${projectId}`,
        data: { project: { invoice_post_due_letters_sent: event.target.value } },
        success: () => { Toast.success('Project Updated!', 'Post due letters sent updated.');},
        error: (data) => { Toast.error('Project could not be updated.', data.responseJSON.error); }
      });
    });
  }
}
