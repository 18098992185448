import moment from 'moment';

export class RecurringSalesEvent {
  static setup() {
    if(document.querySelector('#recurring_sales_event_start_date')) {
      this.setupFrequencyEvent();
      this.checkFrequencyValues();
    }
  }

  static setupFrequencyEvent() {
    $('#recurring_sales_event_frequency').change(function() {
      RecurringSalesEvent.checkFrequencyValues();
    });
  }

  static checkFrequencyValues() {
    let val = $('#recurring_sales_event_frequency').val();
    let startDate =  moment(document.querySelector('#recurring_sales_event_start_date').value);
    if(val === 'months') {
      this.updateDayOfFrequency('Day of month', this.daysOfMonthOptions(startDate.date()));
    } else if(val === 'weeks') {
      this.updateDayOfFrequency('Day of week', this.daysOfWeeksOptions(startDate.day()));
    } else {
      $('#recurring_sales_event_day_of_frequency').val('');
      $('.recurring_sales_event_day_of_frequency').hide();
    }
  }

  static updateDayOfFrequency(label, options) {
    $('.recurring_sales_event_day_of_frequency').show();
    $('#recurring_sales_event_day_of_frequency').val('');
    let dayOfFrequency = $('#recurring_sales_event_day_of_frequency');
    dayOfFrequency.siblings('label').text(label);
    dayOfFrequency.html(options);
  }

  static daysOfMonthOptions(selectedValue) {
    let options = '';
    for(let i = 1; i <= 31; i++) {
      let selected = selectedValue === i ? 'selected' : '';
      options += `<option value="${i}" ${selected}>${i}</option>`;
    }

    return options;
  }

  static daysOfWeeksOptions(selectedValue) {
    let days = { 0: 'Sunday', 1: 'Monday', 2: 'Tuesday', 3: 'Wednesday', 4: 'Thursday', 5: 'Friday', 6: 'Saturday' };
    let options = '';
    Object.entries(days).forEach(([key, value]) => {
      let selected = selectedValue.toString() === key ? 'selected' : '';
      options += `<option value="${key}" ${selected}>${value}</option>`;
    });

    return options;
  }
}
