import $ from 'jquery';
import { BasePage } from './base_page';
import { SignaturesModal } from '../modals/signatures_modal';

export class OnlineFormsEditPage extends BasePage {
  // @override
  setup () {
    $('body').on('click', '.js-online-forms__check-button', (event) => {
      event.preventDefault();
      event.stopPropagation();
      const $label = $(event.currentTarget);
      const $input = $label.find('input');

      if ($input.prop('checked')) {
        $input.prop('checked', false);
        $label.removeClass('active');
      } else {
        $input.prop('checked', true);
        $label.addClass('active');
      }
    });

    $('body').on('click', '.js-online-forms__signature', (event) => {
      const $img = $(event.currentTarget);
      SignaturesModal.start($img);
    });

    $('input[type="file"]').change((event) => {
      const filename = Array.from(event.target.files).map((file) => file.name).join(', ');

      $(event.target).siblings('.custom-file-label').text(filename);
      $(event.target).parents('form').find('input[name="upload_attachment[name]"]').val(filename);
    });
  }
}
