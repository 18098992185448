import '../src/css/utils/collapsible.scss';
import '../src/css/utils/devise.scss';
import '../src/css/utils/mapbox.scss';
import '../src/css/utils/structure.scss';
import '../src/css/main.scss';
import '../src/css/controllers/elo_colors.scss';
import '../src/css/controllers/email_templates.scss';
import '../src/css/controllers/emails.scss';
import '../src/css/controllers/invoices.scss';
import '../src/css/controllers/estimates.scss';
import '../src/css/controllers/projects.scss';
import '../src/css/controllers/signatures.scss';
import '../src/css/production-report.scss';
import '../src/css/sales-dashboard.scss';
import '../src/css/controllers/map_box.scss';
import { Project } from '../src/project';
import { Warranties } from '../src/warranties';
import { ProductionReport } from '../src/production_report';
import { BuildNotesEdit } from '../src/build_notes_edit';
import { CompanyCam } from '../src/company_cam';
import { ProjectReport } from '../src/project_report';
import { AuthorizeNet } from '../src/authorize_net';
import { CopyToInputButton } from '../src/copy_to_input_button';
import { CalendarSetup } from '../src/calendar';
import { RecurringSalesEvent } from '../src/recurring_sales_event';
import { SalesDashboard } from '../src/sales_dashboard';

require('babel-polyfill');
require('jquery-ui');
require('jquery-ui/ui/effect');
require('jquery-ui/ui/effects/effect-bounce');
require('jquery.dirty/dist/jquery.dirty');
require('@nathanvda/cocoon');
require('bootstrap');
require('../src/initializers/index');
require('../src/pages/index');
require('../src/pages/projects_form_page');

$(document).ready(function() {
  Project.setup();
  ProductionReport.setup();
  Warranties.setup();
  BuildNotesEdit.setup();
  CompanyCam.setup();
  ProjectReport.setup();
  AuthorizeNet.setup();
  CopyToInputButton.setup();
  CalendarSetup.setup();
  RecurringSalesEvent.setup();
  SalesDashboard.setup();
});
