import $ from 'jquery';
import { BasePage } from './base_page';

export class OnlineFormsSubmittedPage extends BasePage {
  // @override
  setup () {
    $('#js-form-search').on('change', 'select', (event) => {
      const $input = $(event.currentTarget);
      const $form = $input.closest('form');
      $form.submit();
    });
  }
}
