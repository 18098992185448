export class Project {
  static setup() {
    $('#project_client_id').change(function () {
      Project.updateClientInformation($(this).val());
    });

    $('#project_insurance_company_id').change(function () {
      Project.updateInsuranceCompanyInformation($(this).val());
    });

    $('#project_lead_type').change(function() {
      Project.toggleOriginLead();
    });

    this.toggleOriginLead();
  }

  static toggleOriginLead() {
    let value = $('#project_lead_type').val();

    if(value === 'self_generated') {
      $('#project_origin_lead_id').val('');
      $('#project_true_lead_origin').val('');
      $('.project_origin_lead').hide();
      $('.project_true_lead_origin').hide();
    } else {
      $('.project_origin_lead').show();
      $('.project_true_lead_origin').show();
    }
  }

  static updateClientInformation(record_id) {
    let url = `/clients/${record_id}?project_id=${this.projectID()}`;
    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: url,
      success: (data, _status) => {
        $('#project_client_first_name').val(data.first_name);
        $('#project_client_last_name').val(data.last_name);
        $('#project_client_mobile_phone').val(data.mobile_phone);
        $('#project_client_mobile_phone_note').val(data.mobile_phone_note);
        $('#project_client_phone_number').val(data.phone_number);
        $('#project_client_phone_number_note').val(data.phone_number_note);
        $('#project_client_email').val(data.email);
        $('#project_client_email_note').val(data.email_note);
        $('#project_client_phone_number_2').val(data.phone_number_2);
        $('#project_client_phone_number_2_note').val(data.phone_number_2_note);
        $('#project_client_address_1').val(data.address_1);
        $('#project_client_address_2').val(data.address_2);
        $('#project_client_city').val(data.city);
        $('#project_client_state').val(data.state);
        $('#project_client_zipcode').val(data.zipcode);
        $('#project_client_notes').val(data.notes);

        this.updatePreviousAddresses(data.previous_addresses);
        this.resetClientAddressFields();
      }
    });
  }

  static updateInsuranceCompanyInformation(record_id) {
    let url = `/insurance_companies/${record_id}?project_id=${this.projectID()}`;
    $.ajax({
      type: 'GET',
      dataType: 'json',
      url: url,
      success: (data, _status) => {
        $('#project_insurance_company_phone_number').val(data.phone_number);
        $('#project_insurance_company_email').val(data.email);
      }
    });
  }

  static projectID() {
    return $('#project_id').val();
  }

  static updatePreviousAddresses(previousAddresses) {
    let options = ['<option value=\'\'></option>'];
    options = options.concat(previousAddresses.map(function(address) {
      let dataAttributes = address[2];
      let attributes = [];
      Object.entries(dataAttributes).forEach(entry => {
        const [attr, value] = entry;
        if(value) {
          attributes.push(`${attr}="${value}"`);
        }
      });

      return `<option value="${address[0]}" ${attributes.join(' ')}>${address[1]}</option>`;
    }));

    $('#client_address_selector').html(options.join(''));
  }

  static resetClientAddressFields() {
    $('#project_address_1').val('');
    $('#project_address_2').val('');
    $('#project_city').val('');
    $('#project_state').val('');
    $('#project_zipcode').val('');
  }
}
