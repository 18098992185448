export class SalesDashboard {
  static setup() {
    if (document.querySelector('.sales-dash-expand-button')) {
      SalesDashboard.setupExpandButton();
    }
  }

  static setupExpandButton() {
    const expandButtons = document.querySelectorAll('.sales-dash-expand-button');
    expandButtons.forEach(button => {
      button.addEventListener('click', function(e) {
        e.preventDefault();
        const container = e.target.closest('.sales-rep-chart');
        const canvas = container.querySelector('canvas').parentNode;
        
        if (canvas.style.height === '95vh') {
          canvas.style.height = '400px';
        } else {
          canvas.style.height = '95vh';
          container.scrollIntoView(true, { behavior: 'smooth' });
        }

        container.classList.toggle('col-lg-6');
      });
    });
  }
}