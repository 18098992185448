import $ from 'jquery';
import mustache from 'mustache';
import { RemindersModal } from '../modals/reminders_modal';
import { BasePage } from './base_page';

export class NotesIndexPage extends BasePage {
  // @override
  setup () {
    this.bindReminders();
    this.filterByOfficeType();
  }

  bindReminders () {
    $('.js-reminders-add').off('click');
    $('.js-insurance-notes, .js-company-notes, .js-lead-notes, .js-work_order-notes')
      .on('click', '.js-reminders__add', (event) => {
        event.preventDefault();
        const $link = $(event.currentTarget);
        const url = $link.attr('href');
        RemindersModal.start(url);
      });
  }
  filterByOfficeType () {
    $('#company').on('change', (event) => {
      var selected = $('select#company :selected').val();
      if (selected === '') {
        $('.js--filter-company').show();
        $('.js-accounting-filter-company').show();
        $('.js-sales-filter-company').show();
        $('.js-csr-filter-company').show();
        $('.js-production-filter-company').show();
        $('.js-manager-filter-company').show();
      } else if (selected === 'production') {
        $('.js-production-filter-company').show();
        $('.js-accounting-filter-company').hide();
        $('.js-sales-filter-company').hide();
        $('.js-csr-filter-company').hide();
        $('.js-manager-filter-company').hide();
        $('.js--filter-company').hide();
      } else if (selected === 'accounting') {
        $('.js-accounting-filter-company').show();
        $('.js-production-filter-company').hide();
        $('.js-sales-filter-company').hide();
        $('.js-csr-filter-company').hide();
        $('.js-manager-filter-company').hide();
        $('.js--filter-company').hide();
      } else if (selected === 'sales') {
        $('.js-sales-filter-company').show();
        $('.js-production-filter-company').hide();
        $('.js-accounting-filter-company').hide();
        $('.js-csr-filter-company').hide();
        $('.js-manager-filter-company').hide();
        $('.js--filter-company').hide();
      } else if (selected === 'csr') {
        $('.js-csr-filter-company').show();
        $('.js-production-filter-company').hide();
        $('.js-sales-filter-company').hide();
        $('.js-accounting-filter-company').hide();
        $('.js-manager-filter-company').hide();
        $('.js--filter-company').hide();
      } else if (selected === 'manager') {
        $('.js-manager-filter-company').show();
        $('.js-csr-filter-company').hide();
        $('.js-production-filter-company').hide();
        $('.js-sales-filter-company').hide();
        $('.js-accounting-filter-company').hide();
        $('.js--filter-company').hide();
      }
    });

    $('#lead').on('change', (event) => {
      var selected = $('select#lead :selected').val();
      if (selected === '') {
        $('.js--filter-lead').show();
        $('.js-accounting-filter-lead').show();
        $('.js-sales-filter-lead').show();
        $('.js-csr-filter-lead').show();
        $('.js-manager-filter-lead').show();
        $('.js-production-filter-lead').show();
      } else if (selected === 'production') {
        $('.js-production-filter-lead').show();
        $('.js-accounting-filter-lead').hide();
        $('.js-sales-filter-lead').hide();
        $('.js-csr-filter-lead').hide();
        $('.js-manager-filter-lead').hide();
        $('.js--filter-lead').hide();
      } else if (selected === 'accounting') {
        $('.js-accounting-filter-lead').show();
        $('.js-production-filter-lead').hide();
        $('.js-sales-filter-lead').hide();
        $('.js-csr-filter-lead').hide();
        $('.js-manager-filter-lead').hide();
        $('.js--filter').hide();
      } else if (selected === 'sales') {
        $('.js-sales-filter-lead').show();
        $('.js-production-filter-lead').hide();
        $('.js-accounting-filter-lead').hide();
        $('.js-csr-filter-lead').hide();
        $('.js-manager-filter-lead').hide();
        $('.js--filter').hide();
      } else if (selected === 'csr') {
        $('.js-csr-filter-lead').show();
        $('.js-production-filter-lead').hide();
        $('.js-sales-filter-lead').hide();
        $('.js-accounting-filter-lead').hide();
        $('.js-manager-filter-lead').hide();
        $('.js--filter-lead').hide();
      } else if (selected === 'manager') {
        $('.js-manager-filter-lead').show();
        $('.js-csr-filter-lead').hide();
        $('.js-production-filter-lead').hide();
        $('.js-sales-filter-lead').hide();
        $('.js-accounting-filter-lead').hide();
        $('.js--filter-lead').hide();
      }
    });

    $('form.new_lead_note').on('ajax:success', (event, data) => {
      document.location.reload();
    });
  }
}
