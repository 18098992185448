import $ from 'jquery';
import { BasePage } from './base_page';

export class WorkOrdersIndexPage extends BasePage {
  // @override
  setup () {
    $('#js-wo-search').on('change', 'input, select', (event) => {
      const $input = $(event.currentTarget);
      const $form = $input.closest('form');
      $form.submit();
    });
  }
}
