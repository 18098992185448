import { BasePage } from './base_page';
import Chart from 'chart.js';
import $ from 'jquery';

export class ProjectsShowPage extends BasePage {
  // @override
  setup () {
    this.drawPieChart();
  }

  drawPieChart () {
    let ctx = document.getElementById('pie-chart').getContext('2d');
    let requiredDocCount = $('#pie-chart').data('requiredDocCount');
    let attachedRequiredDocCount = $('#pie-chart').data('attachedRequiredDocCount');
    let requiredDocLeft = requiredDocCount - attachedRequiredDocCount;

    let pieChart = new Chart(ctx, {
      type: 'pie',
      data: {
        labels: ['Remaining Documents', 'Attached Documents'],
        datasets: [{
          data: [requiredDocLeft, attachedRequiredDocCount],
          backgroundColor: [
            'rgba(204, 50, 50, 1)',
            'rgba(45, 201, 55, 1)'
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',
            'rgba(75, 192, 192, 1)'
          ],
          borderWidth: 1
        }]
      }
    });
    return pieChart;
  }
}
