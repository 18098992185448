import swal from 'sweetalert';

export class ProductionReport {
  static setup() {
    this.updateDates();

    $('.table-bottom').each(function() {
      let top = this.getBoundingClientRect().top;
      $(this).css('height', `calc(100vh - ${top}px`);
    });
  }
    
  static updateDates() {
    $('.input-update').change(function () {
      const input = $(this);
      const url = input.data('update-url');
      const field = input.data('update-field');
      const model = input.data('model-name');
      const attribute_values = {};
      attribute_values[field] = input.val();
      const data = {};
      data[model] = attribute_values;
      return $.ajax(url, {
        type: 'PUT',
        data,
        dataType: 'JSON',
        error(data, _textStatus, errorThrown) {
          if(data['responseJSON'] && data['responseJSON'].error) {
            swal({
              title: 'Error',
              text: data['responseJSON'].error,
              icon: 'warning',
              buttons: false,
              dangerMode: true
            });
          } else {
            swal({
              title: 'Error',
              text: errorThrown,
              icon: 'warning',
              buttons: false,
              dangerMode: true
            });
          }
        }
      });
    });
  }
}
