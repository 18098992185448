import $ from 'jquery';
import { BasePage } from './base_page';
import swal from 'sweetalert';

export class EmailsIndexPage extends BasePage {
  // @override
  setup () {
    $('body').on('change', '#js-email-template-id', (event) => {
      const $input = $(event.currentTarget);
      const templateId = $input.val();
      if (templateId !== '') {
        const projectId = $input.data('project-id');
        const url = `/projects/${projectId}/emails/preview`;
        $.ajax({
          type: 'POST',
          dataType: 'html',
          url: url,
          data: {
            email_template_id: templateId
          },
          success: (html) => {
            $('#js-email-preview').html(html);
          },
          error: () => {
            window.alert('There was an error loading the email preview, please try again');
          }
        });
      } else {
        $('#js-email-preview').html('');
      }
    });
  }
}
