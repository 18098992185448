import $ from 'jquery';
import { BasePage } from './base_page';
import swal from 'sweetalert';

export class ProjectsEditPage extends BasePage {
  // @override
  setup () {
    $('body').on('click', '#js-send-giftcard', (event) => {
      event.preventDefault();
      event.stopPropagation();
      const $button = $(event.currentTarget);
      const href = $button.attr('href');
      console.log(href);
      swal({
        title: 'Record Gift Card Sent?',
        text: 'This action cannot be undone',
        icon: 'warning',
        buttons: true
      })
        .then((confirm) => {
          if (confirm) {
            window.location.href = href;
          }
        });
    });

    var lead = $('#project_lead_flag').val();
    if (lead === true) {
      $('#project_sales_rep_1_id').attr('required', true);
    }
  
    $('body').on('click', '#js-project_build-hold', (event) => {
      event.preventDefault();

      const $button = $(event.currentTarget);
      const target = $button.data('target');
      const style = $button.data('style');
      var remove = style;
      var add = 'badge-light';
      var value = false;
      var title = 'Take Build OFF Hold?';
      var text = 'NOTE: you will need to set a build date. Remember to SAVE.';

      if ($button.hasClass('badge-light')) {
        remove = 'badge-light';
        add = style;
        value = true;
        title = 'Place Build ON Hold?';
        text = 'NOTE: this will clear the build date. Remember to SAVE.';
      }
      swal({
        title: title,
        text: text,
        icon: 'warning',
        buttons: true,
        dangerMode: true
      })
        .then((hold) => {
          if (hold) {
            $(target).val(value);
            $button.removeClass(remove).addClass(add);
            if (value) {
              $('#project_build_date').val('');
            } else {
              $('#project_build_date').focus();
            }
          }
        });
    });

    $('body').on('change', '#project_lead_flag', (event) => {
      const $input = $(event.currentTarget);
      if ($input.is(':checked')) {
        return;
      }
      event.preventDefault();

      const $form = $input.closest('form');
      swal({
        title: 'Are you sure?',
        text: 'This will convert the Lead into a Project. This cannot be reversed!',
        icon: 'warning',
        buttons: true,
        dangerMode: true
      })
        .then((willSend) => {
          if (willSend) {
            $form.prop('readonly', false);
          } else {
            $input.prop('checked', true);
          }
        });
    });
  }
}
